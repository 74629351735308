<template>
  <v-responsive>
    <v-container fluid class="pr-0 pl-0">
      <v-card class="mx-auto mb-5 elevation-0 pt-6">
        <v-card class="ml-6 mb-16" elevation="0" max-width="200px" outlined>
          <v-img
            v-if="My.state.item.accountManager && My.state.item.accountManager.thumbnail"
            :src="My.state.item.accountManager.thumbnail"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-avatar tile v-else size="200px">
            <v-icon x-large>mdi-account-tie-outline</v-icon>
          </v-avatar>
        </v-card>
        <v-card-text class="pl-5 pt-0 mt-n10" v-if="My.state.item.accountManager">
          <h2 class="heading font-weight-normal mb-2 mt-3">
            {{ My.state.item.accountManager.name }}
          </h2>
          <a :href="`mailto:${My.state.item.accountManager.email}`">{{
            My.state.item.accountManager.email
          }}</a>
        </v-card-text>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-card class="elevation-0" rounded>
              <v-card-text class="ma-0 pa-3" v-if="!My.state.messageSuccess">
                <v-textarea
                  label="Send a message to my account manager"
                  v-model="My.state.message"
                  flat
                  :rules="[(v) => !!v || $t('commons.rules.errorRequired')]"
                  filled
                  required
                />
              </v-card-text>
              <v-card-text v-if="My.state.messageError || My.state.messageSuccess">
                <span v-if="My.state.messageError" class="red--text"
                  >An error occurred while sending the message, please try again.</span
                >
                <span v-if="My.state.messageSuccess" class="primary--text"
                  >Your message was sent successfully, we will respond to your email as soon as
                  possible.</span
                >
              </v-card-text>
              <v-card-actions v-if="!My.state.messageSuccess">
                <v-btn
                  class="elevation-0 font-weight-regular mb-3"
                  block
                  :loading="My.state.messageLoading"
                  v-on:click="My.actions.sendMessage()"
                  disabled
                >
                  Send
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-title>
          <v-spacer />
          <v-btn
            class="title text-capitalize font-weight-light"
            href="https://support.toroadvertising.com/open.php"
            target="blanck"
            large
            depressed
            text
          >
            <v-icon large>mdi-plus</v-icon>
            <span class="ml-3">Open a New Ticket</span>
          </v-btn>
          <v-spacer />
          <v-btn
            class="title text-capitalize font-weight-light"
            href="https://support.toroadvertising.com/view.php"
            target="blanck"
            large
            depressed
            text
          >
            <v-icon large>mdi-magnify</v-icon>
            <span class="ml-3">Check Ticket Status</span>
          </v-btn>
          <v-spacer />
        </v-card-title>
      </v-card>
    </v-container>
  </v-responsive>
</template>
<script>
import { inject, onMounted, watch } from '@vue/composition-api';
import _My from '../../store/private/My';

export default {
  name: 'affiliate-dashboard',
  components: {},
  setup(props, context) {
    const Application = inject('Application');
    const My = _My(props, context);
    onMounted(async () => {
      await Promise.all([My.actions.loadInfo()]);
    });
    return { Application, My };
  },
};
</script>
<style lang="scss">
.v-avatar--offset {
  top: -38px;
  position: relative;
}
</style>
